<template>
  <div class="section experience">
    <div class="section__header">
      <div class="section__wysiwyg wysiwyg">
        <h2>Work experience</h2>
      </div>
    </div>
    <div class="section__content">

      <div v-for="experience in this.userdata.experiences" :key='experience.id' class="sub-section">
        <div class="sub-section__content">
          <div class="wysiwyg">
            <h3>
              {{experience.title}}
            </h3>
            <div class="exp-formatted-date">
              <p>
                {{experience.company}} {{calculateEmployment(experience)}}
              </p>
              <ul>
                <li class="list__item">{{parseDate(experience.period_start)}} - {{parseDate(experience.period_end)}}</li>
              </ul>
            </div>
            <p>
              {{experience.description}}
            </p>
          </div>
          <div v-if="experience.attachment" class="media">
            <div class="media__list">
              <div class="row">
                <div class="col col_6 col_tab-12">
                  <div class="media__item media-item">
                    <a href="#" @click.prevent="showAttach(experience.attachment)" class="media-item__link" target="_blank">
                      <div class="media-item__media">
                        <svg v-show="experience.attachment.ext_type === 'video'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5932 4.58313C17.843 4.71168 18 4.96906 18 5.25V12.75C18 13.0309 17.843 13.2883 17.5932 13.4169C17.3434 13.5454 17.0427 13.5236 16.8141 13.3603L11.5641 9.6103C11.367 9.46952 11.25 9.24222 11.25 9C11.25 8.75779 11.367 8.53049 11.5641 8.3897L16.8141 4.6397C17.0427 4.47641 17.3434 4.45457 17.5932 4.58313ZM13.2903 9L16.5 11.2926V6.70739L13.2903 9Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.25 4.5C1.83579 4.5 1.5 4.83579 1.5 5.25V12.75C1.5 13.1642 1.83579 13.5 2.25 13.5H10.5C10.9142 13.5 11.25 13.1642 11.25 12.75V5.25C11.25 4.83579 10.9142 4.5 10.5 4.5H2.25ZM0 5.25C0 4.00736 1.00736 3 2.25 3H10.5C11.7426 3 12.75 4.00736 12.75 5.25V12.75C12.75 13.9926 11.7426 15 10.5 15H2.25C1.00736 15 0 13.9926 0 12.75V5.25Z" fill="#00A961" />
                        </svg>
                        <svg v-show="experience.attachment.ext_type === 'document'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.90901 1.40901C3.33097 0.987053 3.90326 0.75 4.5 0.75H10.5C10.6989 0.75 10.8897 0.829018 11.0303 0.96967L15.5303 5.46967C15.671 5.61032 15.75 5.80109 15.75 6V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H4.5C3.90326 17.25 3.33097 17.0129 2.90901 16.591C2.48705 16.169 2.25 15.5967 2.25 15V3C2.25 2.40326 2.48705 1.83097 2.90901 1.40901ZM4.5 2.25C4.30109 2.25 4.11032 2.32902 3.96967 2.46967C3.82902 2.61032 3.75 2.80109 3.75 3V15C3.75 15.1989 3.82902 15.3897 3.96967 15.5303C4.11032 15.671 4.30109 15.75 4.5 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V6.31066L10.1893 2.25H4.5Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.75C10.9142 0.75 11.25 1.08579 11.25 1.5V5.25H15C15.4142 5.25 15.75 5.58579 15.75 6C15.75 6.41421 15.4142 6.75 15 6.75H10.5C10.0858 6.75 9.75 6.41421 9.75 6V1.5C9.75 1.08579 10.0858 0.75 10.5 0.75Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 9.75C5.25 9.33579 5.58579 9 6 9H12C12.4142 9 12.75 9.33579 12.75 9.75C12.75 10.1642 12.4142 10.5 12 10.5H6C5.58579 10.5 5.25 10.1642 5.25 9.75Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 12.75C5.25 12.3358 5.58579 12 6 12H12C12.4142 12 12.75 12.3358 12.75 12.75C12.75 13.1642 12.4142 13.5 12 13.5H6C5.58579 13.5 5.25 13.1642 5.25 12.75Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.25 6.75C5.25 6.33579 5.58579 6 6 6H7.5C7.91421 6 8.25 6.33579 8.25 6.75C8.25 7.16421 7.91421 7.5 7.5 7.5H6C5.58579 7.5 5.25 7.16421 5.25 6.75Z" fill="#00A961" />
                        </svg>
                        <svg v-show="experience.attachment.ext_type === 'image'" class="files__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961" />
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961" />
                        </svg>
                      </div>
                      <div class="media-item__content">
                        <span class="media-item__title">{{experience.attachment.user_defined_filename}}</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="type == 'edit'" class="sub-section__action">
          <a href="#" @click.prevent="editExpirience(experience)" class="mr-10">
            <svg class="btn__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40901 2.90901C1.83097 2.48705 2.40326 2.25 3 2.25H8.25C8.66421 2.25 9 2.58579 9 3C9 3.41421 8.66421 3.75 8.25 3.75H3C2.80109 3.75 2.61032 3.82902 2.46967 3.96967C2.32902 4.11032 2.25 4.30109 2.25 4.5V15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H13.5C13.6989 15.75 13.8897 15.671 14.0303 15.5303C14.171 15.3897 14.25 15.1989 14.25 15V9.75C14.25 9.33579 14.5858 9 15 9C15.4142 9 15.75 9.33579 15.75 9.75V15C15.75 15.5967 15.5129 16.169 15.091 16.591C14.669 17.0129 14.0967 17.25 13.5 17.25H3C2.40326 17.25 1.83097 17.0129 1.40901 16.591C0.987053 16.169 0.75 15.5967 0.75 15V4.5C0.75 3.90326 0.987053 3.33097 1.40901 2.90901Z" fill="#00A961"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.15906C14.777 2.15906 14.5631 2.24766 14.4053 2.40538L7.42724 9.38349L7.03079 10.9693L8.61658 10.5728L15.5947 3.59472C15.7524 3.437 15.841 3.22309 15.841 3.00005C15.841 2.777 15.7524 2.56309 15.5947 2.40538C15.437 2.24766 15.2231 2.15906 15 2.15906ZM13.3447 1.34472C13.7837 0.905697 14.3791 0.659058 15 0.659058C15.6209 0.659058 16.2163 0.905697 16.6553 1.34472C17.0944 1.78374 17.341 2.37918 17.341 3.00005C17.341 3.62092 17.0944 4.21636 16.6553 4.65538L9.53035 11.7804C9.43423 11.8765 9.31379 11.9447 9.18192 11.9777L6.18192 12.7277C5.92634 12.7915 5.65597 12.7167 5.46969 12.5304C5.2834 12.3441 5.20851 12.0737 5.27241 11.8181L6.02241 8.81815C6.05538 8.68627 6.12357 8.56584 6.21969 8.46972L13.3447 1.34472Z" fill="#00A961"/>
            </svg>
          </a>
          <a href="#" @click.prevent="deleteExpirience(experience)" class="mr-10">
            <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#00A961"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#00A961"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#00A961"></path>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#00A961"></path>
            </svg>
          </a>
        </div>
      </div>

      <div v-if="type == 'edit'" class="form-field">
        <div class="form-field__content">
          <a href="#" @click.prevent="addExperiencePopup" class="btn btn_hex">
            <svg class="btn__ico" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.48413 3C9.89834 3 10.2341 3.33579 10.2341 3.75V14.25C10.2341 14.6642 9.89834 15 9.48413 15C9.06992 15 8.73413 14.6642 8.73413 14.25V3.75C8.73413 3.33579 9.06992 3 9.48413 3Z" fill="#00A961"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3.48413 9C3.48413 8.58579 3.81992 8.25 4.23413 8.25H14.7341C15.1483 8.25 15.4841 8.58579 15.4841 9C15.4841 9.41421 15.1483 9.75 14.7341 9.75H4.23413C3.81992 9.75 3.48413 9.41421 3.48413 9Z" fill="#00A961"/>
            </svg>
            <span class="btn__text">Add work experience</span>
          </a>
        </div>

      </div>
    </div>
    <ExperiencePopup></ExperiencePopup>
  </div>
</template>

<script>
import ExperiencePopup from '@/components/popups/Experience'
import store from '@/store'

export default {
  name: "Experience",
  components: {
    ExperiencePopup
  },
  beforeMount() {
    this.getExperiences();
  },
  props: {
    type: String,
  },
  data() {
    return {
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    }
  },
  methods: {
    getExperiences() {
      store.dispatch('GET_USER_EXPERIENCES');
    },
    addExperiencePopup(){
      store.dispatch('SHOW_POPUP', { popup: 'experiencePopup', show: true});
    },
    editExpirience(experience) {
      store.dispatch('SHOW_POPUP', { popup: 'experiencePopup', show: true, data: experience });
    },
    deleteExpirience(experience) {
      store.commit('USER_SET_EXP_TO_DELETE', experience.id);
      store.dispatch('SHOW_POPUP', {
        popup: 'confirmPopup',
        show: 'Are you sure you want to delete the record: "'+experience.title+'"?',
        route: '',
        name: 'REMOVE_EXP',
        data: '',
      });
    },
    showAttach(attachment) {
      store.dispatch('SHOW_POPUP', { popup: 'viewfilePopup', show: true, data: attachment });
    },
    parseDate(item) {
      if(item) {
        if(item === 'Present') return 'Present';
        if(/^\d+$/.test(item)) return item;
        let date = new Date(item);
        return this.monthNames[date.getMonth()] + ' ' + date.getFullYear();
      }
      return item;
    },
    calculateEmployment(item) {
      if(item.period_start) {
        if(item.period_end) {
          let start = Date.parse(item.period_start)
          let end = (item.period_end === 'Present') ? Date.now() : Date.parse(item.period_end);
          let range = (end - start) / 2629743000; // to month count
          if(range > 0) return ' - '+ (parseFloat(range/12).toFixed(1)) + ' years of employment';
        }
      }
      return;
    },
  },
  computed: {
    userdata: () => store.state.user,
    popups: () => store.state.popups,
  }
}
</script>
