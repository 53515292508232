<template>
  <div :class="{ 'popup-visible' : this.popups.experiencePopup }">
    <div class="popup-wrap" @click.self="closePopup">
      <div v-if="this.popups.experiencePopup" class="popup experience-popup" :class="{ 'active' : this.popups.experiencePopup }">

        <div class="popup__header">
          <div class="popup__title">
            <span class="title-text">{{popups.experienceData ? 'Edit' : 'Add'}} experience</span>
          </div>
          <div @click="closePopup" class="popup-remove">
            <svg class="ico" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z" fill="#333333"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#333333"/>
            </svg>
          </div>
        </div><!-- //popup__header -->
        <form class="main__form" autocomplete="off">
          <div class="popup__content">
            <div class="popup__section">
              <div class="row">
                <div class="col col_12">
                  <div class="form-field">
                    <div class="form-field__header">
                      <label class="form-field__title">Position title*</label>
                    </div>
                    <div class="form-field__content">
                      <input v-model="data.title" type="text" placeholder="Enter the position title"
                      :class="{ 'input-errors' : this.errors.title }" class="form-field__input" required>
                      <p v-if="this.errors.title" class="form-errors">{{ this.errors.title }}</p>
                    </div>
                  </div><!-- //form-field -->
                </div>
                <div class="col col_12">
                  <div class="form-field">
                    <div class="form-field__header">
                      <label class="form-field__title">Company name*</label>
                    </div>
                    <div class="form-field__content">
                      <input v-model="data.company" type="text" placeholder="Enter company name" class="form-field__input"
                      :class="{ 'input-errors' : this.errors.company }" required>
                      <p v-if="this.errors.company" class="form-errors">{{ this.errors.company }}</p>
                    </div>
                  </div><!-- //form-field -->
                </div>

                <div class="col col_12">
                  <div class="form-field">
                    <div class="form-field__content">
                      <div class="custom-check">
                        <label class="custom-check__label pl_0">
                          <input class="custom-check__input" v-model="selectPresent" type="checkbox">
                          <svg class="custom-check__ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g><rect  x="0.5" y="0.5" width="17" height="17" rx="3.5" fill="#00A961" stroke="#000"/><g><path fill-rule="evenodd" clip-rule="evenodd" d="M14.2166 4.94997C14.5204 5.25371 14.5204 5.74618 14.2166 6.04992L7.79996 12.4666C7.49622 12.7703 7.00376 12.7703 6.70001 12.4666L3.78335 9.54992C3.47961 9.24618 3.47961 8.75372 3.78335 8.44997C4.08709 8.14623 4.57955 8.14623 4.88329 8.44997L7.24999 10.8167L13.1167 4.94997C13.4204 4.64623 13.9129 4.64623 14.2166 4.94997Z" fill="white"/></g></g>
                          </svg>
                          <span class="custom-check__text">I am currently working in this role</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col col_12">
                  <div class="form-field">
                    <div class="form-field__header">
                      <label class="form-field__title">Start date*</label>
                    </div>
                    <div class="form-field__content row">
                      <div class="col col_6">
                        <select v-model="startMonth">
                          <option value="-1">Select mounth</option>
                          <option v-for="(month, key) in monthNames" :key='key' :value="key">{{month}}</option>
                        </select>
                        <p v-if="this.errors.period_start" class="form-errors">{{ this.errors.period_start }}</p>
                      </div>
                      <div class="col col_6">
                        <select v-model="startYear" :class="{ 'input-errors' : this.errors.years }" >
                          <option value="-1">Select year</option>
                          <option v-for="year in years" :key='year' :value="year">{{year}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div :class="{'disabled_area': selectPresent}" class="col col_12">
                  <div class="form-field">
                    <div class="form-field__header">
                      <label class="form-field__title">End date*</label>
                    </div>
                    <div class="form-field__content row">
                      <div class="col col_6">
                        <select v-model="endMonth">
                          <option value="-1">Select mounth</option>
                          <option v-for="(month, key) in monthNames" :key='key' :value="key">{{month}}</option>
                        </select>
                      </div>
                      <div class="col col_6">
                        <select v-model="endYear" :class="{ 'input-errors' : this.errors.years }" >
                          <option value="-1">Select year</option>
                          <option v-for="year in years" :key='year' :value="year">{{year}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="this.errors.years" class="col col_12 form-errors mb_10 mt-10">{{ this.errors.years }}</div>

                <div class="col col_12">
                  <div class="form-field">
                    <div class="form-field__header">
                      <label class="form-field__title">Description</label>
                    </div>
                    <div class="form-field__content border-bug" :class="{ 'input-errors' : this.errors.description }">
                      <textarea v-model="data.description" name="textarea" class="form-field__input"></textarea>
                    </div>
                  </div><!-- //form-field -->
                </div>

                <div class="col col_12">
                  <div class="form-field">
                    <div class="form-field__header">
                      <label class="form-field__title">Attach file</label>
                    </div>
                    <div class="form-field__content">
                      <div class="attach">
                        <div class="attach__list">

                          <div v-if="this.popups.experienceData.attachment && !this.file && !this.removedAttach" class="attach__item attach-item">
                            <div class="attach-item__ico">
                              <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961"/>
                              </svg>
                            </div>
                            <div class="attach-item__title">
                              <span class="title-text">
                                {{this.popups.experienceData.attachment.user_defined_filename}}
                              </span>
                            </div>
                            <div @click="clearAttach" class="attach-item__remove">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#333333"/>
                              </svg>
                            </div>
                          </div>

                          <div v-show="this.file" class="attach__item attach-item">
                            <div class="attach-item__ico">
                              <svg class="ico" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 3C3.33579 3 3 3.33579 3 3.75V14.25C3 14.6642 3.33579 15 3.75 15H14.25C14.6642 15 15 14.6642 15 14.25V3.75C15 3.33579 14.6642 3 14.25 3H3.75ZM1.5 3.75C1.5 2.50736 2.50736 1.5 3.75 1.5H14.25C15.4926 1.5 16.5 2.50736 16.5 3.75V14.25C16.5 15.4926 15.4926 16.5 14.25 16.5H3.75C2.50736 16.5 1.5 15.4926 1.5 14.25V3.75Z" fill="#00A961"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6.375 6C6.16789 6 6 6.16789 6 6.375C6 6.58211 6.16789 6.75 6.375 6.75C6.58211 6.75 6.75 6.58211 6.75 6.375C6.75 6.16789 6.58211 6 6.375 6ZM4.5 6.375C4.5 5.33947 5.33947 4.5 6.375 4.5C7.41053 4.5 8.25 5.33947 8.25 6.375C8.25 7.41053 7.41053 8.25 6.375 8.25C5.33947 8.25 4.5 7.41053 4.5 6.375Z" fill="#00A961"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 6.96967C11.7626 6.67678 12.2374 6.67678 12.5303 6.96967L16.2803 10.7197C16.5732 11.0126 16.5732 11.4874 16.2803 11.7803C15.9874 12.0732 15.5126 12.0732 15.2197 11.7803L12 8.56066L4.28033 16.2803C3.98744 16.5732 3.51256 16.5732 3.21967 16.2803C2.92678 15.9874 2.92678 15.5126 3.21967 15.2197L11.4697 6.96967Z" fill="#00A961"/>
                              </svg>
                            </div>
                            <div class="attach-item__input">
                              <input type="file" id="file_exp" class="input" ref="file_exp" @change.prevent="handleFileUpload">
                            </div>
                            <div class="attach-item__title">
                              <span class="title-text">
                                {{this.file.name}}
                              </span>
                            </div>
                            <div @click="clearAttach" class="attach-item__remove">
                              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 4.5C1.5 4.08579 1.83579 3.75 2.25 3.75H15.75C16.1642 3.75 16.5 4.08579 16.5 4.5C16.5 4.91421 16.1642 5.25 15.75 5.25H2.25C1.83579 5.25 1.5 4.91421 1.5 4.5Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 2.25C7.30109 2.25 7.11032 2.32902 6.96967 2.46967C6.82902 2.61032 6.75 2.80109 6.75 3V3.75H11.25V3C11.25 2.80109 11.171 2.61032 11.0303 2.46967C10.8897 2.32902 10.6989 2.25 10.5 2.25H7.5ZM12.75 3.75V3C12.75 2.40326 12.5129 1.83097 12.091 1.40901C11.669 0.987053 11.0967 0.75 10.5 0.75H7.5C6.90326 0.75 6.33097 0.987053 5.90901 1.40901C5.48705 1.83097 5.25 2.40326 5.25 3V3.75H3.75C3.33579 3.75 3 4.08579 3 4.5V15C3 15.5967 3.23705 16.169 3.65901 16.591C4.08097 17.0129 4.65326 17.25 5.25 17.25H12.75C13.3467 17.25 13.919 17.0129 14.341 16.591C14.7629 16.169 15 15.5967 15 15V4.5C15 4.08579 14.6642 3.75 14.25 3.75H12.75ZM4.5 5.25V15C4.5 15.1989 4.57902 15.3897 4.71967 15.5303C4.86032 15.671 5.05109 15.75 5.25 15.75H12.75C12.9489 15.75 13.1397 15.671 13.2803 15.5303C13.421 15.3897 13.5 15.1989 13.5 15V5.25H4.5Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5C7.91421 7.5 8.25 7.83579 8.25 8.25V12.75C8.25 13.1642 7.91421 13.5 7.5 13.5C7.08579 13.5 6.75 13.1642 6.75 12.75V8.25C6.75 7.83579 7.08579 7.5 7.5 7.5Z" fill="#333333"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5C10.9142 7.5 11.25 7.83579 11.25 8.25V12.75C11.25 13.1642 10.9142 13.5 10.5 13.5C10.0858 13.5 9.75 13.1642 9.75 12.75V8.25C9.75 7.83579 10.0858 7.5 10.5 7.5Z" fill="#333333"/>
                              </svg>
                            </div>
                          </div>
                        </div>

                        <div class="attach__init" @click.prevent="uploadInit">
                          <a href="#" class="btn btn_secondary">
                            <span class="btn__text">Upload file</span>
                          </a>
                        </div>

                        <div class="attach__wysiwyg wysiwyg">
                          <p>Maximum allowed file size is 10MB</p>
                        </div>
                      </div>
                    </div>
                  </div><!-- //form-field -->
                </div>
              </div>
            </div>
          </div>
        </form>
        <div class="popup__footer">
          <div class="btn-group btn-group_secondary content-between">
            <a href=""  @click.prevent="closePopup" class="btn btn_secondary">
              <span class="btn__text">Cancel</span>
            </a>
            <a href="#" @click.prevent="saveExperience" class="btn btn_primary">
              <span class="btn__text">Save experience</span>
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "ExperiencePopup",
  components: {

  },
  data() {
    return {
      data: {
        title: '',
        company: '',
        period_start: '',
        period_end: '',
        description: '',
      },
      file: '',
      removedAttach: false,
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      startMonth: -1,
      startYear: -1,
      endMonth: -1,
      endYear: -1,
      selectPresent: false,
      errors:{
        title: false,
        company: false,
        description: false,
        years: false,
        show: false,
        period_start: false,
      }
    }
  },
  methods: {
    getLoaded(){
      jcf.setOptions('Select', {
        wrapNative: false,
        useCustomScroll: false,
        fakeDropInBody: false,
        multipleCompactStyle: true
      });

      jcf.replaceAll();
    },

    uploadInit: function() {
      const elem = this.$refs.file_exp;
      elem.click();
    },

    handleFileUpload: function() {
      this.file = this.$refs.file_exp.files[0];
      this.removedAttach = false;
    },

    clearAttach: function() {
      this.file = '';
      this.removedAttach = true;
    },

    closePopup() {
      this.clearData();
      store.dispatch('SHOW_POPUP', { popup: 'experiencePopup', show: false});
    },

    clearData() {
      this.resetErrors();
      this.removedAttach = false;
      this.file = '';
      this.selectPresent = false;
      this.startMonth = -1;
      this.startYear = -1;
      this.endMonth = -1;
      this.endYear = -1;
      this.data = {
        title: '',
        company: '',
        period_start: '',
        period_end: '',
        description: '',
      };
    },

    resetErrors() {
      this.errors.show = false;
      this.errors.title = false;
      this.errors.company = false;
      this.errors.description = false;
      this.errors.years = false;
      this.errors.period_start = false;
    },

    async saveExperience() {
      this.resetErrors();

      if(!this.data.title){
        this.errors.title = 'This field is required.';
        this.errors.show = true;
      }
      if(!this.data.company){
        this.errors.company = 'This field is required.';
        this.errors.show = true;
      }

      if(this.startYear < 0){
        this.errors.period_start = 'Start date is required.';
        this.errors.show = true;
      }

      if(this.endYear < 0 && !this.selectPresent){
        this.errors.years = 'End date is required.';
        this.errors.show = true;
      }

      this.data.period_start = (this.startMonth < 0) ? this.startYear : (this.startYear+'-'+('0'+(this.startMonth+1)).slice(-2)+'-01');
      this.data.period_start = this.data.period_start.toString();

      if(this.selectPresent) this.data.period_end = 'Present';
      else this.data.period_end = (this.endMonth < 0) ? this.endYear : (this.endYear+'-'+('0'+(this.endMonth+1)).slice(-2)+'-01');
      this.data.period_end = this.data.period_end.toString();


      let time_start = Date.parse(this.data.period_start);
      let time_end = (this.selectPresent) ? Date.now() : Date.parse(this.data.period_end);

      if((time_end - time_start) < 0){
        this.errors.years = 'End date can’t be earlier than start date.';
        this.errors.show = true;
      }

      if(this.errors.show) {
        return;
      }

      if(this.file) {
        await store.dispatch('PUT_FILE', this.file);
      }

      if(this.popups.experienceData.attachment && !this.file && !this.removedAttach){
        this.data.attachment = this.popups.experienceData.attachment.id;
      } else if(this.removedAttach) {
        this.data.removedAttach = this.popups.experienceData.attachment.id;
        this.data.attachment = false;
      } else {
        this.data.attachment = this.userdata.put_file.id;
      }

      store.dispatch('SAVE_EXPERIENCE', this.data);
      this.clearData();
      this.userdata.put_file = '';
    }
  },
  computed: {
    popups: () => store.state.popups,
    userdata: () => store.state.user,
    years() {
      const year = new Date().getFullYear();
      return Array.from({length: year - (year-50)}, (value, index) => year - index);
    }
  },
  watch: {
    'popups.experienceData'(newVal, oldVal) {
      console.log(oldVal)
      if(newVal) this.data = this.popups.experienceData;
      if(newVal.period_start) {
        if(/^\d{4}$/.test(newVal.period_start)) {
          this.startMonth = -1;
          this.startYear = newVal.period_start;
        } else {
          let date = new Date(newVal.period_start);
          this.startMonth = date.getMonth();
          this.startYear = date.getFullYear();
        }
      }

      if(newVal.period_end) {
        this.selectPresent = false;
        if(newVal.period_end === 'Present') {
          this.endMonth = '';
          this.endYear = '';
          this.selectPresent = true;
        } else if(/^\d{4}$/.test(newVal.period_end)) {
          this.endMonth = -1;
          this.endYear = newVal.period_end;
        } else {
          let date = new Date(newVal.period_end);
          this.endMonth = date.getMonth();
          this.endYear = date.getFullYear();
        }
      }
    },
  },
  mounted(){
    this.getLoaded();
  },
  updated(){
    jcf.destroyAll();
    jcf.setOptions('Select', {
      wrapNative: false,
      useCustomScroll: false,
      fakeDropInBody: false,
      multipleCompactStyle: true
    });
    jcf.replaceAll();
  }
}
</script>
